// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;

import './vendors/jquery-ui.min'
import './vendors/bootstrap'
import 'jquery-parallax.js'
import './vendors/slick';


// TODO: toggle position absolute and position relative of tab items

if (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== "undefined" && $.browser.msie == 1))
{
  $(document).ready(function(){
    setTimeout(function(){
      $('body').addClass('version-ie');
    }, 800);
  });
}else{
  document.addEventListener('DOMContentLoaded', function() {
    var $content = $('.main__content');
    var $loaderCont = $('<div>',{ class: 'loader__container'});
    var $loader = $('<div>',{ class: 'loader'});
  
    $loaderCont.append($loader);
    $('body').append($loaderCont);
  })

// Load Function
$(window).on('load', function(){
  // $('html, body').scrollTop(0);

  var $content = $('.main__content');
  $('body').addClass('is-shown');

  $('.loader__container').addClass('is-entering');
  $('.loader').one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
  function(event) {
    $('.loader__container').remove();
  });

  // ======================================
  // Exit Loader Animation
  // ==================================================
  // Hijack Link redirection to allow animate out
  $('a:not(.noredirect)').on('click', function(){
    var href = $(this).attr('href');

    // Delay for animation to run
    var animDur = 1200;

    var $loaderCont = $('<div>',{ class: 'loader__container'});
    var $loader = $('<div>',{ class: 'loader exit-loader'});

    $loaderCont.append($loader);
    $('body').append($loaderCont);

    animDur = parseFloat($('.loader').css('transition-duration'))*1000;

    // console.log(animDur);

    setTimeout(function(){
      $('.loader__container').addClass('is-exiting');
    },10);

    $('.loader').one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
    function(event) {
      $('body').removeClass('is-shown');
    });

    setTimeout(function(){
      window.location = href;
    }, animDur);

    return false;
  });
  // ==================================================
  // END Exit Loader Animation
  // ======================================
});
}

$(function(){

  // check if mobile
  var tabCheck;
  var mobCheck;

  if( $(window).width() < 992 ) tabCheck = true;
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || $(window).width() < 768 ) mobCheck = true;

  // Header Dropdown Function
  $('.dropdown').hover(function(){

    var height = $(this).attr('data-height');

    $(this).addClass('is-open');
    if(height == '190') $('.header__container').addClass('is-open px190');
    if(height == '180') $('.header__container').addClass('is-open px180');
    if(height == '170') $('.header__container').addClass('is-open px170');
    if(height == '140') $('.header__container').addClass('is-open px140');
    if(height == '120') $('.header__container').addClass('is-open px120');
    if(height == '70') $('.header__container').addClass('is-open px70');
    if(height == '50') $('.header__container').addClass('is-open px50');

  },function(){

    $(this).removeClass('is-open');
    $('.header__container').removeClass('is-open px190 px180 px170 px140 px120 px70 px50');
  });

  if(!mobCheck){
    //Header Shrink function
    $( window ).on('scroll', function(){
      if($(window).scrollTop() >= 100 ){
        $('header').addClass('shrink-header');
        if(!$('body').hasClass('landing')) $('.scrolltoTop').addClass('is-shown');
      }else{
        $('header').removeClass('shrink-header');
        $('.scrolltoTop').removeClass('is-shown');
      }
    });
  }else{
    $( window ).on('scroll', function(){
      if($(window).scrollTop() > 100 ){
        if(!$('body').hasClass('landing')) $('.scrolltoTop').addClass('is-shown');
      }else{
        $('.scrolltoTop').removeClass('is-shown');
      }
    });
  }

  // Header Menu Button
  $('.menu-button').on('click', function(){
    $(this).toggleClass('is-open');
    $('header .nav-links').toggleClass('is-open');
  });

  // Landing Page Slider Config

  if($('body').hasClass('landing')){
    $('.slider').slick({
      arrows: false,
      autoplay: true,
      autoplaySpeed: 15000,
      dots: true,

      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,

      fade: true,
      speed: 600,
      cssEase: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)'
    });

    $(window).on('resize', function(){
      clearTimeout(timeout);
      var timeout = setTimeout(function() {
        var currentSlide = $('.slider').slick('slickCurrentSlide');
        $('.slider').slick('slickGoTo', currentSlide, true);
        // console.log('currentSlide');
      }, 400);
    });

    if(!mobCheck){
      var elems;
      $('.slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        var cs = slick.$slides[nextSlide];
        elems = cs.querySelectorAll('.animated');

        // console.log(elems.length);

        if(nextSlide !== currentSlide){
          for(var i = 0; i < elems.length; i++){
            elems[i].classList.add('pause-anim');
            elems[i].classList.remove('animated');
          }
        }
      });

      $('.slider').on('afterChange', function(event, slick, currentSlide){
        for(var i = 0; i < elems.length; i++){
          elems[i].classList.remove('pause-anim');
          elems[i].classList.add('animated');
        }
      });
    }
  }

  if(!$('.main__content').hasClass('landing')){
    $(window).on('resize', function(){
      setTimeout(function(){
        $(window).trigger('resize').trigger('scroll');
      }, 20);
    });
  }

  // Create Scroll to Top Button

  var bh = $('body').height();
  var wh = $(window).height();

  if(bh > wh){
    buildScrolltoTop();
  }

  function buildScrolltoTop(){
    var $content = $('.main__content');
    var $stt = $('<div>', { class:'scrolltoTop' });
    var $sttClass = $('.scrolltoTop');

    $content.append($stt);
  }

  $('.scrolltoTop').on('click', function(){
    $('html, body').animate({scrollTop: 0}, 600, 'easeInOutQuint');
  });

  $('.tab__content').on("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
  function(event) {

  });

  if(!tabCheck){
    // Tab list Function
    var prev = 1;
    $('.tab__list li[data-tab-index]').on('click', function(){
      var index = $(this).attr('data-tab-index');

      var prevTab = $('.tab__content[data-content-index="'+prev+'"]');
      var currentTab = $('.tab__content[data-content-index="'+index+'"]');

      // scroll to the top of the div
      var offset = $('.main__content').offset().top;
      if($(window).scrollTop() > offset) $('html, body').animate({scrollTop: offset}, 350, 'easeInOutCubic');

      //change tab list highlighting
      $('.tab__list li').removeClass('is-open');
      $(this).addClass('is-open');

      // fade out prev tab
      prevTab.addClass('fadeOutTab');
      //timeout to remove classes after faded out
      setTimeout(function(){
        prevTab.removeClass('is-open fadeOutTab');
      }, 400);

      // set new content to block to allow fading animation
      currentTab.addClass('block');
      // add fade in class seperately to allow animation to execute
      setTimeout(function(){
        currentTab.addClass('fadeInTab');
      }, 10);

      // set new content to final open state
      setTimeout(function(){
        currentTab.removeClass('block fadeInTab').addClass('is-open');
      }, 400);

      prev = index;
    });
  }else{
    // Create Select
    var $select = $("<select>", { id: "mobile-select" });
    var $selectcont = $("<div>", { class:'col-xs-12 mobile-select__container' });

    // Get Original li values
    var list = $('.tab__list li');
    list.each(function(index, item){
      // get index
      var i = $(item).attr('data-tab-index');
      // get html
      var html = $(this).html();
      //create option
      var $opt = $("<option>");

      //set value
      $opt.attr('value', i);
      $opt.html(html);

      $select.append($opt);
    });

    $selectcont.append($select);
    // replace tab list with new select
    $('.tab__list').replaceWith($selectcont);

    // change depends on value
    $('#mobile-select').on('change', function(){
      var index = parseInt($(this).find(':selected').attr('value'));

      if(!index){
        var t = $(this).find(':selected a').attr('href');
        window.location.href = t;

        return;
      }

      $('.tab__content').removeClass('is-open');
      $('.tab__content[data-content-index="'+index+'"]').addClass('is-open');
    });
  }

  // List Sorter Functionality
  $('.sorter__select').on('change', function(){
    var $parent = $(this).closest('.tab__content');
    var date = $(this).find(':selected').attr('value');

    // console.log(date);

    if(date == 'all'){
      $parent.find('.scroller-list li').removeClass('filtered');
    }else{
      $parent.find('.scroller-list li').addClass('filtered');
      $parent.find('.scroller-list li').has('span:contains("'+ date +'")').removeClass('filtered');
    }
  });
});

// Accordion Milestone

$(document).ready(function () {
  // Add minus icon for collapse element which is open by default
  $("#accordionMilestone .collapse.show").each(function () {
    $(this)
      .prev(".card-header")
      .find(".fa")
      .addClass("fa-minus")
      .removeClass("fa-plus");
  });

  // Toggle plus minus icon on show hide of collapse element
  $("#accordionMilestone .collapse")
    .on("show.bs.collapse", function () {
      $(this)
        .prev(".card-header")
        .find(".fa")
        .removeClass("fa-plus")
        .addClass("fa-minus");
    })
    .on("hide.bs.collapse", function () {
      $(this)
        .prev(".card-header")
        .find(".fa")
        .removeClass("fa-minus")
        .addClass("fa-plus");
    });
});
